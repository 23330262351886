@media only screen and (max-width: 768px) {
  .DictionaryDefintion {
    margin: 0 auto;
    padding: 1.5em 3em;
    max-width: 304px;
    .phonetic {
      font-size: 1.96875em;
    }
  }
}
@media only screen and (min-width: 769px) {
  .DictionaryDefintion {
    padding: 3em 6em;
    margin: 3em auto;
    .phonetic {
      font-size: 4.5em;
    }
  }
}
.DictionaryDefintion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background: transparent;
  background: linear-gradient(180deg, #230e3e 0%, #1e1633 100%);
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  .phonetic {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    // font-size: 36px;
    line-height: 100%;
    /* identical to box height, or 36px */

    letter-spacing: -0.07em;
    text-transform: lowercase;

    color: #ffffff;
    margin: 10px 0px;
  }

  .subtitle {
    display: flex;
    gap: 10px;
    h2 {
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 100%;
      /* or 13px */

      letter-spacing: -0.07em;
      text-transform: lowercase;

      color: #ffffff;
    }
    .article {
      font-weight: 700;
    }
  }

  .definition {
    font-style: italic;
    li {
      text-align: left;
    }
  }

  .synonyms {
    color: white;
    font-size: 13px;
    font-family: 'Helvetica';
    font-weight: 300;
  }

  .definition,
  synonyms {
    font-family: 'Helvetica';
    font-weight: 300;
    font-size: 13px;
    line-height: 100%;
    /* or 13px */

    letter-spacing: -0.07em;

    color: #ffffff;
  }

  .synonyms > span {
    font-weight: 700;
  }
}
