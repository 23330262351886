@media only screen and (max-width: 768px) {
  .SideNav {
    position: fixed;
    right: 0;
    height: 100vh;
    overflow-x: hidden;
    width: 90vw;
    background-color: #ad9db8;
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    .SideNavMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 0px;
      gap: 17px;
      list-style: none;
      margin: 5em 1.5em;
      .SideNavItem {
        font-family: 'Helvetica';
        font-style: normal;
        font-weight: 700;
        font-size: 56.25px;
        line-height: 100%;
        text-align: right;
        letter-spacing: -0.07em;
        text-transform: capitalize;
        text-decoration: none;

        color: #000000;
      }
    }
  }
}
@media only screen and (min-width: 769px) {
  .SideNav {
    display: none;
  }
}
