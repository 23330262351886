@media only screen and (max-width: 768px) {
  .Navbar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-areas: '. title menuIcon';
    padding: 8px 0px;

    .SaintStephHeader {
      font-size: 2em;
      grid-area: 'title';
      grid-column: 2/7;
      margin: 0;
    }

    .menuIcon {
      grid-area: 'menuIcon';
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      grid-column: 7/7;
    }
    .MenuItems {
      display: none;
    }

    .Socials {
      display: none;
    }
  }
}

@media only screen and (min-width: 769px) {
  .Navbar {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-areas: 'menuItems title socials';
    padding: 8px 40px;

    .MenuItems {
      grid-area: 'menuItems';
      grid-column: 1/3;
      display: flex;
      list-style: none;
      gap: 1em;
      align-items: center;
    }

    .SaintStephHeader {
      font-size: 2.5em;
      grid-area: 'title';
      grid-column: 3/8;
      margin: 0.25em 0em;
    }

    .menuIcon {
      display: none;
    }

    .MenuItem:hover,
    .SocialIcon:hover {
      opacity: 0.5;
      cursor: pointer;
    }
  }
}

// General Rules
.Navbar {
  justify-items: center;
  align-items: center;
  background: #ad9db8;

  .SaintStephHeader,
  .MenuItem {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    line-height: 100%;

    letter-spacing: -0.07em;
    text-transform: capitalize;

    color: #000000;
  }

  .MenuItem {
    font-size: 1.5em;
    text-decoration: none;
  }
}
