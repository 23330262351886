$url: 'https://storage.googleapis.com/saint_steph_site_photos/Me%20at%20PH%20Studio%201.png';

@media only screen and (max-width: 768px) {
  .Banner {
    // background-image: url($url); /* W3C */
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1)),
      url($url);
    background-repeat: no-repeat;
    background-position: 75% 0%;
    // background-size: 200%;
  }
}
@media only screen and (min-width: 769px) {
  .Banner {
    background-image: linear-gradient(180deg, transparent, rgba(0, 0, 0, 1)),
      url($url);
    background-repeat: no-repeat;
    background-size: 100%;
  }
}

.Banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 90vh;
}
