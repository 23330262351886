@media only screen and (max-width: 768px) {
  .HeaderWithBtn {
    .SiteTitle {
      font-size: 3em;
    }
    .SiteButton {
      font-size: 1em;
    }
  }
}
@media only screen and (min-width: 769px) {
  .HeaderWithBtn {
    .SiteTitle {
      font-size: 7.5em;
    }
    .SiteButton {
      font-size: 2em;
    }
  }
}

// General Rules
.HeaderWithBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2em;
  margin-bottom: 4em;
  .SiteTitle {
    // position: absolute;
    // left: 0;
    // right: 0;

    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;

    line-height: 100%;
    /* identical to box height, or 50px */

    letter-spacing: -0.07em;
    text-transform: capitalize;
    color: white;
    margin: 0 auto;
  }
  .SiteButton {
    // position: absolute;
    // left: 0;
    // right: 0;

    width: 200px;
    padding: 10px 40px;

    margin: 0 auto;
    background: #af9db0;
    border-radius: 60px;
    font-family: 'Helvetica';
    font-style: italic;
    font-weight: 300;

    line-height: 100%;
    /* identical to box height, or 20px */

    letter-spacing: -0.07em;
    text-transform: capitalize;
    text-decoration: none;

    color: black;
    &:hover {
      opacity: 0.5;
    }
  }
}
