@media only screen and (max-width: 768px) {
  .Socials {
    display: flex;
    grid-area: 'socials';
    grid-column: 9/9;
    gap: 1em;
    align-items: center;
    justify-content: center;
    .SocialIcon {
      height: 2em;
    }
  }
}
@media only screen and (min-width: 769px) {
  .Socials {
    display: flex;
    grid-area: 'socials';
    grid-column: 9/9;
    gap: 1em;
    .SocialIcon {
      height: 2em;
    }
  }
}
