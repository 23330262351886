@media only screen and (max-width: 768px) {
  .Biography {
    font-size: 1em;
    max-width: 304px;
  }
}
@media only screen and (min-width: 769px) {
  .Biography {
    font-size: 1.5em;
    max-width: 665px;
  }
}
.Biography {
  width: 85vw;
  margin: 0 auto;

  font-family: 'Helvetica';
  font-style: italic;
  font-weight: 300;
  line-height: 100%;
  /* or 13px */

  text-align: left;
  letter-spacing: -0.07em;
  text-transform: lowercase;

  color: #ffffff;
}
